import React from 'react';
import { Navigate, Outlet } from 'react-router';

interface ProtectedRoutes{
    isAllow: boolean;
    children?: React.ReactNode;
}

const ProtectedRoutes: React.FC <ProtectedRoutes> = ({ isAllow , children }) => {
    if(!isAllow){
        return <Navigate to='/login' replace />
    }
    return children ? <> {children} </> : <Outlet />
}

export default ProtectedRoutes;