import React, { createContext, useCallback, useEffect, useState } from 'react'

interface User {
  id: number
  username: string
  email: string
  firstname: string
  lastname: string
  token: string
  tokenExpiry?: number
}

interface AuthContextType {
  user: User | null
  logout: () => void
  updateProfile: (updatedUser: Partial<User>) => void
  storeUser: (userInfo: User) => void
  isLoading: boolean
}

export const AuthContext = createContext<AuthContextType | undefined>(undefined)

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  // define the state
  const [user, setUser] = useState<User | null>(null)
  const [isLoading, setLoading] = useState(true)

  // save user to localStorage
  const saveUserToLocalStorage = (user: User) => {
    localStorage.setItem('POC', JSON.stringify(user))
  }

  // get user from localStorage
  const loadUserFromLocalStorage = (): User | null => {
    const storedUser = localStorage.getItem('POC')
    return storedUser ? JSON.parse(storedUser) : null
  }

  // clear user from localStorage
  const clearUserFromLocalStorage = () => {
    localStorage.removeItem('POC')
  }

  const setupTokenExpiration = useCallback((expiryTime: number) => {
    const timeout = expiryTime - Date.now()
    if (timeout > 0) {
      setTimeout(logout, timeout)
    } else {
      logout()
    }
  }, [])

  useEffect(() => {
    const storedUser = loadUserFromLocalStorage()
    if (
      storedUser &&
      storedUser.tokenExpiry &&
      storedUser.tokenExpiry > Date.now()
    ) {
      setUser(storedUser)
      setupTokenExpiration(storedUser.tokenExpiry)
    } else {
      clearUserFromLocalStorage()
    }
    setLoading(false)
  }, [setupTokenExpiration])

  const logout = useCallback(() => {
    setUser(null)
    clearUserFromLocalStorage()
  }, [])

  const updateProfile = useCallback(
    (updatedUser: Partial<User>) => {
      if (user) {
        const updatedUserData = { ...user, ...updatedUser }
        setUser(updatedUserData)
        saveUserToLocalStorage(updatedUserData)
        if (updatedUserData.tokenExpiry) {
          setupTokenExpiration(updatedUserData.tokenExpiry)
        }
      }
    },
    [user, setupTokenExpiration]
  )

  const storeUser = useCallback(
    (userInfo: User) => {
      const tokenLifetime = 24 * 60 * 60 * 1000
      const tokenExpiry = Date.now() + tokenLifetime
      const userWithExpiry = { ...userInfo, tokenExpiry }
      setUser(userWithExpiry)
      saveUserToLocalStorage(userWithExpiry)
      setupTokenExpiration(tokenExpiry)
    },
    [setupTokenExpiration]
  )

  return (
    <AuthContext.Provider
      value={{ user, logout, updateProfile, storeUser, isLoading }}
    >
      {children}
    </AuthContext.Provider>
  )
}
