import React, { Suspense, lazy } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Loader from "../pages/Loader/Loader";
import ProtectedRoutes from "../context/ProtectedRoutes";
import { AuthProvider } from "../context/AuthContext";
import { useAuth } from "../hooks/useAuth";
import NotFound from "../pages/NotFound/NotFound";


// Lazy-loaded components
const Layout = lazy(() => import('../Layout/Layout'));
const LandingPage = lazy(() => import("../pages/Landing Page/LandingPage"));
const Login = lazy(() => import("../pages/Login/Login"));
const CreateAccount = lazy(() => import("../pages/Create Account/CreateAccount"));
// const UserDashboard = lazy(() => import("../pages/User Dashboard/Dashboard"));
const ManageAccount = lazy(() => import("../pages/Manage Account/Manage_Account"));
const MyBusiness = lazy(() => import("../pages/My Business/My_Business"));
const MyProfile = lazy(() => import("../pages/My Profile/My_Profile"));
const Support = lazy(() => import("../pages/Support/Support"));
const Transactions = lazy(() => import("../pages/Transactions/Transactions"));
const WithdrawFunds = lazy(() => import("../pages/Withdraw Funds/Withdraw_Funds"));
const DepositFunds = lazy(() => import("../pages/Deposit Funds/Deposite_Funds"));
const InternalTransfers = lazy(() => import("../pages/Internal Transfers/Internal_Transfers"));

const Routing = () => {
    const { user, isLoading } = useAuth()

    if (isLoading) return <Loader />;

    const getDefaultRoute = () => (user ? '/manage-account' : '/login');

    return (
        <BrowserRouter>
            <Suspense fallback={<Loader />}>
                <Routes>
                    {/* Routes that do notsss require Layout */}
                    <Route path="/" element={user ? <Navigate to={getDefaultRoute()} /> : <LandingPage />} />
                    <Route path="/login" element={user ? <Navigate to={getDefaultRoute()} /> : <Login />} />
                    <Route path="/create-account" element={user ? <Navigate to={getDefaultRoute()} /> : <CreateAccount />} />

                    {/* Protected Routes  */}
                    {/* Routes that require Layout */}
                    <Route
                        element={
                            <ProtectedRoutes
                                isAllow={!!user}
                            />
                        }
                    >
                        <Route element={<Layout />}>
                            {/* <Route path="/dashboard" element={<UserDashboard />} /> */}
                            <Route path="/manage-account" element={<ManageAccount />} />
                            <Route path="/my-business" element={<MyBusiness />} />
                            <Route path="/my-profile" element={<MyProfile />} />
                            <Route path="/support" element={<Support />} />
                            <Route path="/transaction" element={<Transactions />} />
                            <Route path="/withdraw" element={<WithdrawFunds />} />
                            <Route path="/deposite" element={<DepositFunds />} />
                            <Route path="/internal-transfers" element={<InternalTransfers />} />
                        </Route>
                    </Route>
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </Suspense>
        </BrowserRouter>
    );
}

export default Routing;
