// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
}

.not-found-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  background-color: #111827;
  color: #333;
  overflow: hidden;
  padding: 0 20px;
}

.animated-404 {
  font-size: 10rem;
  color: #FF9820;
  display: flex;
  gap: 10px;
}

.animated-404 span {
  display: inline-block;
  animation: bounce 1s infinite alternate;
}

.animated-404 span:nth-child(1) {
  animation-delay: 0s;
}

.animated-404 span:nth-child(2) {
  animation-delay: 0.2s;
}

.animated-404 span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes bounce {
  to {
    transform: translateY(-30px);
  }
}

.not-found-container p {
  font-size: 1.5rem;
  /* margin: 20px 0; */
  color: #555;
}

.home-link {
  display: inline-block;
  padding: 15px 30px;
  background-color: #FF9820;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-size: 1rem;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.home-link:hover {
  background-color: #FF9820;
  transform: scale(1.05);
}
`, "",{"version":3,"sources":["webpack://./src/stylesheets/notFound.css"],"names":[],"mappings":"AAEA;EACE,SAAS;EACT,kCAAkC;AACpC;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,kBAAkB;EAClB,yBAAyB;EACzB,WAAW;EACX,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,cAAc;EACd,aAAa;EACb,SAAS;AACX;;AAEA;EACE,qBAAqB;EACrB,uCAAuC;AACzC;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE;IACE,4BAA4B;EAC9B;AACF;;AAEA;EACE,iBAAiB;EACjB,oBAAoB;EACpB,WAAW;AACb;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,yBAAyB;EACzB,YAAY;EACZ,qBAAqB;EACrB,kBAAkB;EAClB,eAAe;EACf,2DAA2D;AAC7D;;AAEA;EACE,yBAAyB;EACzB,sBAAsB;AACxB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');\n\nbody {\n  margin: 0;\n  font-family: 'Poppins', sans-serif;\n}\n\n.not-found-container {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  height: 100vh;\n  text-align: center;\n  background-color: #111827;\n  color: #333;\n  overflow: hidden;\n  padding: 0 20px;\n}\n\n.animated-404 {\n  font-size: 10rem;\n  color: #FF9820;\n  display: flex;\n  gap: 10px;\n}\n\n.animated-404 span {\n  display: inline-block;\n  animation: bounce 1s infinite alternate;\n}\n\n.animated-404 span:nth-child(1) {\n  animation-delay: 0s;\n}\n\n.animated-404 span:nth-child(2) {\n  animation-delay: 0.2s;\n}\n\n.animated-404 span:nth-child(3) {\n  animation-delay: 0.4s;\n}\n\n@keyframes bounce {\n  to {\n    transform: translateY(-30px);\n  }\n}\n\n.not-found-container p {\n  font-size: 1.5rem;\n  /* margin: 20px 0; */\n  color: #555;\n}\n\n.home-link {\n  display: inline-block;\n  padding: 15px 30px;\n  background-color: #FF9820;\n  color: white;\n  text-decoration: none;\n  border-radius: 5px;\n  font-size: 1rem;\n  transition: background-color 0.3s ease, transform 0.3s ease;\n}\n\n.home-link:hover {\n  background-color: #FF9820;\n  transform: scale(1.05);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
