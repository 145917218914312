// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100..900;1,100..900&family=Inter:wght@100..900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: "Archivo", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #111827 !important;
  color: white !important;
}

code {
  font-family:  "Archivo", sans-serif;
}

.main-contents{
  padding: 30px 80px 30px 80px;
}

.number::-webkit-outer-spin-button,
.number::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Apply CSS filter to change the color of the default date picker icon */
input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(1) brightness(1) contrast(1); /* Example filter */
}



@media screen and (max-width: 1300px) {
  .main-contents{
    padding: 20px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/stylesheets/index.css"],"names":[],"mappings":"AAEA;EACE,SAAS;EACT,6CAA6C;EAC7C,mCAAmC;EACnC,kCAAkC;EAClC,oCAAoC;EACpC,uBAAuB;AACzB;;AAEA;EACE,mCAAmC;AACrC;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;;EAEE,wBAAwB;EACxB,SAAS;AACX;;AAEA,yEAAyE;AACzE;EACE,2CAA2C,EAAE,mBAAmB;AAClE;;;;AAIA;EACE;IACE,aAAa;EACf;AACF","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100..900;1,100..900&family=Inter:wght@100..900&display=swap');\n\nbody {\n  margin: 0;\n  font-family: \"Archivo\", sans-serif !important;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  background-color: #111827 !important;\n  color: white !important;\n}\n\ncode {\n  font-family:  \"Archivo\", sans-serif;\n}\n\n.main-contents{\n  padding: 30px 80px 30px 80px;\n}\n\n.number::-webkit-outer-spin-button,\n.number::-webkit-inner-spin-button {\n  -webkit-appearance: none;\n  margin: 0;\n}\n\n/* Apply CSS filter to change the color of the default date picker icon */\ninput[type=\"date\"]::-webkit-calendar-picker-indicator {\n  filter: invert(1) brightness(1) contrast(1); /* Example filter */\n}\n\n\n\n@media screen and (max-width: 1300px) {\n  .main-contents{\n    padding: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
