import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './stylesheets/App.css';
import Routing from './routes/routes';
import { AuthProvider } from './context/AuthContext';

function App() {
  return (
    <AuthProvider>
    <Routing />
    </AuthProvider>
  );
}

export default App;
