import React from 'react'
import { Link } from 'react-router-dom';
import '../../stylesheets/notFound.css';

const NotFound: React.FC = () => {
    return (
        <>
            <div className="not-found-container">
                <div className="animated-404">
                    <span>4</span>
                    <span>0</span>
                    <span>4</span>
                </div>
                <p>Oops! The page you are looking for does not exist.</p>
                <Link to="/manage-account" className="home-link">Go to Homepage</Link>
            </div>
        </>
    )
}

export default NotFound