import React from 'react'
import '../../stylesheets/loader.css'

const Loader : React.FC = () => {
    return(
        <>
            <div className="d-flex align-items-center justify-content-center" style={{minHeight:'100vh'}}>
                <div className="loader"></div>
            </div>
        </>
    )
}

export default Loader